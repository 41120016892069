import React, { PropsWithChildren } from 'react'
import {
  ContentButtonItem,
  ContentColumn,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import styled from 'styled-components'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  BackgroundPathType,
  STYLE_CLEAR_FIX,
  STYLE_SAFE_BACKGROUND_IMAGE,
} from '@/styles/presets'
import {
  FONT_16,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_FONT_SIZE,
  STYLE_KEEP_WORD,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import Grid from '@/components/Grid'
import AutoLinkText from '../AutoLinkText'
import { IconTypeFile } from '../Icon'
import { GridVerticalAlignType } from '@/enums/Grid'
import { ContentTextAlign } from '@/enums/Content'

interface Grid2GalleryProps {
  item: ContentRow
}

const Partial = {
  Part({ gallery, className }: { gallery: ContentColumn; className?: string }) {
    if (!gallery) {
      return null
    }
    const { image, title, text, button } = elementItemHelper(gallery.items)
    return (
      <Styled.Part className={className}>
        <Partial.Image image={image} />
        <Partial.Title title={title} />
        <Partial.Text text={text} />
        <Partial.Button button={button} />
      </Styled.Part>
    )
  },
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) {
      return null
    }
    return (
      <>
        {image.map((item) => (
          <DisplayConfigWrapper key={item.src} show={item.show}>
            <Styled.Image {...item} alt={item.alt || ''} />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) {
      return null
    }
    return (
      <>
        {title.map((item, index) => (
          <DisplayConfigWrapper
            show={item.show}
            key={`Grid2Gallery-title-${index}`}
          >
            <Styled.Title as={item.as} textAlign={item.textAlign}>
              {item.value}
            </Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) {
      return null
    }
    return (
      <>
        {text.map((item, index) => (
          <DisplayConfigWrapper
            show={item.show}
            key={`Grid2Gallery-text-${index}`}
          >
            <Styled.Text textAlign={item.textAlign}>{item.value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) {
      return null
    }
    return (
      <Styled.ButtonWrap>
        {button.map((item, index) => {
          return (
            <Styled.Button
              key={`Grid2Gallery-button-${index}`}
              href={item.href}
              target={item.target}
            >
              {item.label}
            </Styled.Button>
          )
        })}
      </Styled.ButtonWrap>
    )
  },
}

function Grid2Gallery({ item }: PropsWithChildren<Grid2GalleryProps>) {
  const [firstGallery, secondGallery] = item?.columns ?? []

  if (!firstGallery && !secondGallery) {
    return null
  }

  return (
    <Styled.Wrap spacing verticalAlign={GridVerticalAlignType.TOP}>
      <Partial.Part className='first-item' gallery={firstGallery} />
      <Partial.Part gallery={secondGallery} />
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled(Grid)`
    margin: 0 auto;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
  `,
  Part: styled.div`
    ${STYLE_CLEAR_FIX};
    text-align: center;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      &:not(.first-item) {
        padding-top: 60px;
      }
    }
  `,
  Image: styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  `,
  Title: styled.strong<{ textAlign?: ContentTextAlign }>`
    ${STYLE_KEEP_WORD};
    display: block;
    margin-top: 50px;
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK01};
    white-space: pre-wrap;
    text-align: ${({ textAlign }) => textAlign};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 24px;
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p<{ textAlign?: ContentTextAlign }>`
    ${STYLE_KEEP_WORD};
    margin-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    text-align: ${({ textAlign }) => textAlign};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 12px;
    }
  `,
  ButtonWrap: styled.div`
    width: 330px;
    margin: 24px auto 0;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      margin-top: 12px;
    }
  `,
  Button: styled(AutoLinkText)`
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding: 8px 53px 8px 20px;
    font-size: ${STYLE_FONT_SIZE._16};
    border: 1px solid ${STYLE_COLOR.BLACK03};
    text-decoration: none;
    line-height: ${STYLE_LINE_HEIGHT._30};
    text-align: left;
    color: ${STYLE_COLOR.BLACK01};

    & + & {
      margin-top: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: calc(50% - 8px);

      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_DOWNLOAD,
        16,
        16,
        BackgroundPathType.ICONS
      )}
    }
  `,
}

export default Grid2Gallery
