import React, { PropsWithChildren } from 'react'
import {
  ContentButtonItem,
  ContentRow,
  ContentTextItem,
  ContentVideoItem,
} from '@/types/Content'
import { ContentTextColor, ContentTextSize } from '@/enums/Content'
import styled, { css } from 'styled-components'
import {
  FONT_16,
  FONT_18,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import Video from '@/components/Video'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import elementItemHelper from '@/helpers/elementItemHelper'
import {
  STYLE_ARROW_BUTTON_WHITE,
  STYLE_OUTLINE_BUTTON_LARGE,
} from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import Grid from '@/components/Grid'
import AutoLinkText from '@/components/AutoLinkText'

interface Grid1FullVideoProps {
  item: ContentRow
}

interface Grid1FullVideoStyledProps {
  backgroundImageUrl?: string
  color?: ContentTextColor
  size?: ContentTextSize
  main?: boolean
  videoHeight?: number
}

const Partial = {
  Video({ video }: { video: ContentVideoItem[] }) {
    if (!video) return null
    return (
      <>
        {video?.map((item, index) => (
          <Styled.Fixer
            key={`Grid1FullVideo-video-${index}`}
            show={item.show}
            videoHeight={item.height}
          >
            <Styled.Video {...item} />
          </Styled.Fixer>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <TransitionHandler key={`Grid1FullVideo-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as}>{value}</Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show, as }, index) => (
          <TransitionHandler key={`Grid1FullVideo-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text as={as}>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  SubTitle({ subTitle }: { subTitle: ContentTextItem[] }) {
    if (!subTitle) return null
    return (
      <>
        {subTitle?.map(({ value, show }, index) => (
          <TransitionHandler key={`Grid1FullVideo-subTitle-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.SubTitle>{value}</Styled.SubTitle>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({
    button,
    isSingleButton,
  }: {
    button: ContentButtonItem[]
    isSingleButton: boolean
  }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullVideo-button-${index}`}
          >
            {isSingleButton && (
              <Styled.Button {...item}>{item?.label}</Styled.Button>
            )}
            {!isSingleButton && (
              <Styled.LargeButton {...item}>{item?.label}</Styled.LargeButton>
            )}
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid1FullVideo({ item }: PropsWithChildren<Grid1FullVideoProps>) {
  const { columns } = item
  const [{ items }] = columns ?? [{}]
  const { video, title, text, button, subTitle } = elementItemHelper(items)
  const isSingleButton = button?.length === 1

  return (
    <Styled.Wrap>
      <Partial.Video video={video} />
      <Styled.TextWrap spacing>
        <Styled.Inner>
          <Partial.Title title={title} />
          <Partial.Text text={text} />
          <Partial.SubTitle subTitle={subTitle} />
          <TransitionHandler>
            <Partial.Button button={button} isSingleButton={isSingleButton} />
          </TransitionHandler>
        </Styled.Inner>
      </Styled.TextWrap>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div<Grid1FullVideoStyledProps>`
    overflow: hidden;
    position: relative;
  `,
  Fixer: styled(DisplayConfigWrapper)<Grid1FullVideoStyledProps>`
    height: ${({ videoHeight }) => videoHeight}px;
  `,
  Video: styled(Video)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ width, height }) => {
      if (typeof width !== 'number' || typeof height !== 'number') {
        return css`
          width: 100%;
          height: 100%;
        `
      }
      return css`
        width: 100%;
        min-width: ${(width / height) * 100}vh;
        height: 100%;
        min-height: ${(height / width) * 100}vw;
      `
    }};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-height: 215.957vw;
      object-fit: cover;
    }
  `,
  TextWrap: styled(Grid)`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-top: 120px;
    box-sizing: border-box;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      top: initial;
      bottom: 0;
      padding: 0;
    }
  `,
  Inner: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: absolute;
      left: 0;
      right: 0;
      top: auto;
      bottom: 60px;
      padding: 0 6.6%;
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    white-space: pre-wrap;
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  SubTitle: styled.div`
    padding-top: 50px;
    padding-bottom: 30px;
    ${FONT_22_EXTRA_BOLD};
    color: ${STYLE_COLOR.YELLOW};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
      padding-bottom: 12px;
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_WHITE};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18};
      padding-top: 12px;
    }
  `,
  LargeButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON_LARGE};
    min-width: 265px;
    text-align: center;
    border: 1px solid ${STYLE_COLOR.WHITE04_30};
    color: ${STYLE_COLOR.WHITE02_60};
    margin-right: 10px;
    margin-bottom: 10px;

    &:hover {
      border: 1px solid ${STYLE_COLOR.YELLOW};
      color: ${STYLE_COLOR.YELLOW};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      width: 200px;
      height: 56px;
      padding: 14px 0;
      text-align: center;
      box-sizing: border-box;
      ${FONT_18};

      & + & {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  `,
}

export default Grid1FullVideo
