import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  ContentBackgroundItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'
import LinedTabs from '@/components/LinedTabs'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_SYSTEM_GRID_INSIDE } from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { FONT_16, FONT_22_EXTRA_BOLD, FONT_30_EXTRA_BOLD } from '@/styles/fonts'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'

interface Grid1FullBackgroundTabProps {
  item: ContentRow
}

interface Grid1FullBackgroundTabStyledProps {
  width?: number | string
  height?: number | string
  src?: string
  isCurrent?: boolean
  isPrev?: boolean
}

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <TransitionHandler key={`Grid1FullBackgroundTab-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as}>{value}</Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <TransitionHandler key={`Grid1FullBackgroundTab-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  TabText({
    tabText,
    currentTabId,
  }: {
    tabText: ContentTextItem[]
    currentTabId: number
  }) {
    if (!tabText?.length) {
      return null
    }
    return (
      <>
        {tabText?.map(({ value, show, tabId }, index) => (
          <TransitionHandler
            key={`Grid1FullBackgroundTab-tabText-${index}`}
            effect={EffectType.FADE_IN}
            speed={2}
          >
            <Styled.ImageTextWrap
              show={show}
              isCurrent={tabId === currentTabId}
            >
              <Styled.ImageText aria-hidden={tabId !== currentTabId}>
                {value}
              </Styled.ImageText>
            </Styled.ImageTextWrap>
          </TransitionHandler>
        ))}
      </>
    )
  },
  TabImage({
    tabImage,
    currentTabId,
    prevTabId,
  }: {
    tabImage: ContentImageItem[]
    currentTabId: number
    prevTabId: number
  }) {
    if (!tabImage) return null
    return (
      <>
        {tabImage?.map(({ src, tabId, show, alt }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid1FullBackgroundTab-tabImage-${index}`}
          >
            <Styled.Image
              src={src}
              isCurrent={tabId === currentTabId}
              isPrev={tabId === prevTabId}
              alt={alt || ''}
            />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  BackgroundImage({
    backgroundImage,
    children,
  }: {
    backgroundImage: ContentBackgroundItem[]
    children: ReactNode
  }) {
    if (!backgroundImage) return null
    return (
      <>
        {backgroundImage?.map(({ src, width, height, show }, index) => (
          <Styled.BackgroundImage
            show={show}
            key={`Grid1FullBackgroundTab-backgroundImage-${index}`}
            src={src}
            width={width}
            height={height}
          >
            {children}
          </Styled.BackgroundImage>
        ))}
      </>
    )
  },
}

function Grid1FullBackgroundTab({ item }: Grid1FullBackgroundTabProps) {
  const { columns } = item
  const [{ items }] = columns ?? [{}]

  const [currentTabId, setCurrentTabId] = useState<number>(0)
  const [prevTabId, setPrevTabId] = useState<number>(undefined)

  const handleClickTab = (index: number): void => {
    if (index === currentTabId) {
      return undefined
    }
    setPrevTabId(currentTabId)
    return setCurrentTabId(index)
  }

  useEffect(() => {
    if (prevTabId === undefined) {
      return
    }
    const timeout = setTimeout(() => {
      setPrevTabId(undefined)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [prevTabId])

  const { tab, title, text, backgroundImage, tabImage, tabText } =
    elementItemHelper(items)

  return (
    <Partial.BackgroundImage backgroundImage={backgroundImage}>
      <Styled.Fixer spacing>
        <>
          <Styled.TextWrap>
            <Partial.Title title={title} />
            <Partial.Text text={text} />
            <TransitionHandler effect={EffectType.FADE_IN} speed={2}>
              <Styled.LinedTabs list={tab} onClick={handleClickTab} />
            </TransitionHandler>
          </Styled.TextWrap>
          <Styled.ImageWrap>
            <Partial.TabText tabText={tabText} currentTabId={currentTabId} />
            <Partial.TabImage
              tabImage={tabImage}
              currentTabId={currentTabId}
              prevTabId={prevTabId}
            />
          </Styled.ImageWrap>
        </>
      </Styled.Fixer>
    </Partial.BackgroundImage>
  )
}

const Styled = {
  BackgroundImage: styled(
    DisplayConfigWrapper
  )<Grid1FullBackgroundTabStyledProps>`
    width: 100%;
    height: ${({ height }) =>
      typeof height === 'number' ? `${height}px` : height};
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${({ src }) => src});
  `,
  Fixer: styled(Grid)`
    position: relative;
    overflow: hidden;
  `,
  TextWrap: styled.div`
    position: relative;
    z-index: 1;
    max-width: 50%;
  `,
  Title: styled.div`
    ${STYLE_SYSTEM_GRID_INSIDE};
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    padding-top: 94px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 60px;
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.div`
    ${STYLE_SYSTEM_GRID_INSIDE};
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    padding-top: 24px;
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  LinedTabs: styled(LinedTabs)`
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
    max-width: 300px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 24px;
    }
  `,
  ImageWrap: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  `,
  ImageTextWrap: styled(
    DisplayConfigWrapper
  )<Grid1FullBackgroundTabStyledProps>`
    position: absolute;
    top: 150px;
    left: 33.3%;
    width: 33.3%;
    white-space: pre-line;
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    padding-bottom: 30px;
    opacity: 0;
    transition: all 0.5s ease-out;
    transform: translate3d(50px, 0, 0);
    z-index: 10;
    ${({ isCurrent }) => {
      return (
        isCurrent &&
        css`
          display: block;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.8s;
        `
      )
    }}
  `,
  ImageText: styled.span`
    display: block;

    &::after {
      content: '';
      display: block;
      margin-top: 30px;
      border-bottom: 1px solid ${STYLE_COLOR.WHITE04_30};
      transition: all 0.6s 0.6s ease-out;
    }
  `,
  Image: styled.img<Grid1FullBackgroundTabStyledProps>`
    position: absolute;
    bottom: 0;
    left: -80px;
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(124%, -100%, 0) scale(0.8) skewX(-10deg);
    ${({ isCurrent }) => {
      return (
        isCurrent &&
        css`
          transform: translate3d(0, 0, 0) scale(1) skewX(0deg);
          transition: all 1s 0.2s ease-out;
        `
      )
    }};
    ${({ isPrev }) => {
      return (
        isPrev &&
        css`
          transform: translate3d(-124%, 100%, 0) scale(1.2) skewX(-10deg);
          transition: all 0.8s ease-in;
        `
      )
    }};
  `,
}

export default Grid1FullBackgroundTab
