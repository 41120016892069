import Grid from '@/components/Grid'
import { FONT_16 } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import styled from 'styled-components'

function UploadStepper() {
  return (
    <Grid>
      <Styled.Wrap>
        <Styled.StepItem>
          <Styled.AdInfo>
            <img
              src={`${process.env.JSON_DATA_EXTERNAL_URI}14-stepper-join.png`}
              alt=''
            />
            <p>가입 및 계정 생성</p>
          </Styled.AdInfo>
        </Styled.StepItem>

        <Styled.StepItem>
          <Styled.AdInfo>
            <img
              src={`${process.env.JSON_DATA_EXTERNAL_URI}14-stepper-purchase.png`}
              alt=''
            />
            <p>상품 구매</p>
          </Styled.AdInfo>
        </Styled.StepItem>

        <Styled.StepItem>
          <Styled.AdInfo>
            <img
              src={`${process.env.JSON_DATA_EXTERNAL_URI}14-stepper-upload.png`}
              alt=''
            />
            <p>소재 등록</p>
          </Styled.AdInfo>
        </Styled.StepItem>

        <Styled.StepItem>
          <Styled.AdInfo>
            <img
              src={`${process.env.JSON_DATA_EXTERNAL_URI}14-stepper-view.png`}
              alt=''
            />
            <p>노출</p>
          </Styled.AdInfo>
        </Styled.StepItem>

        <Styled.StepItem>
          <Styled.AdInfo>
            <img
              src={`${process.env.JSON_DATA_EXTERNAL_URI}14-stepper-report.png`}
              alt=''
            />
            <p>리포트 확인</p>
          </Styled.AdInfo>
        </Styled.StepItem>
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  Wrap: styled.ol`
    display: flex;
    width: 100%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-direction: column;
      & > div + div {
        margin-top: 40px;
      }
    }
  `,
  StepItem: styled.li`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      & + &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 48px;
        height: 152px;
        transform: translateX(-50%);
        background-image: ${`url(${process.env.CDN_URI}/icons/ic_48_arrow_right.svg)`};
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: middle;
      }
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      & + & {
        margin-top: 40px;
      }
    }
  `,
  IconWrap: styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%);
    display: flex;
    align-items: center;
  `,
  AdInfo: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 152px;
    }
    p {
      margin-top: 10px;
      ${FONT_16};
      color: ${STYLE_COLOR.BLACK02};
      text-align: center;

      @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
        margin-top: 0;
      }
    }
  `,
}

export default UploadStepper
